// Prompt for the Resource Delivery Study, launched early 2025.
//
// Note that this folder exports a useShouldDisplay hook which additionally
// limits when the prompt may display.
//
// Rendering calls the Optimizer to acquire two different conditions for the
// user, one which controls the text shown in the prompt, and one which controls
// their destination should they choose to "goToSurvey."
//
// Two query string parameters are attached to the survey URL, `hid` as a
// hashed user identifer, and `lcid` for their lowest-performing learning
// condition (obfuscated).
//
// If testing locally, make sure the Optimizer dev process is running.
//
// See [Resource Delivery Study Spec](https://docs.google.com/document/d/1M0kJbc_vVHYxngCNmyFSuTp8qZADWPGpDb67FryAWHQ/edit?tab=t.0)

import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { boolean, object, ObjectSchema } from 'yup';
import uri from 'urijs';

import { Modal, Row, Col, Button, Input, Loading, Select } from '@perts/ui';

import { useCurrentUserQuery, useOptimizerCondition } from 'models';
import { useClassReportData } from 'pages/Reports/ClassReport23/useClassReportData';
import { sha256Digest } from 'utils';
import { getLearningConditionId } from './getLearningConditionId';

type FormValues = { goToSurvey: boolean };

export const initialValues: FormValues = { goToSurvey: true };

export const validationSchema: ObjectSchema<FormValues> = object({
  goToSurvey: boolean().required(),
});

enum WordingCondition {
  direct = 'direct',
  supportive = 'supportive',
}

type Content = {
  title: JSX.Element;
  body: JSX.Element | null;
  label: JSX.Element;
};

const conditionContent: Record<WordingCondition, Content> = {
  [WordingCondition.direct]: {
    title: <>See Practice Ideas Related to a Priority Learning Condition</>,
    body: null,
    label: (
      <>
        As you review your new round of data, would you like to see some
        practices you can test in your classroom?
      </>
    ),
  },
  [WordingCondition.supportive]: {
    title: <>Get Ideas to Support Your Students</>,
    body: (
      <>
        Congratulations on achieving strong survey participation in this class.
        By listening to and learning from students’ responses, you’re doing a
        lot to support them.
      </>
    ),
    label: (
      <>
        As you review your new round of data, would you like to see some
        practices you can test in your classroom?
      </>
    ),
  },
};

// eslint-disable-next-line complexity
export const Component = () => {
  const { data: user } = useCurrentUserQuery();
  const classReportData = useClassReportData();
  const { data: wordingData } = useOptimizerCondition(
    'message-framing-25',
    'prompt-wording',
    user?.uid ?? '',
  );
  const { data: surveyData } = useOptimizerCondition(
    'resource-delivery-25',
    'redirect-link',
    user?.uid ?? '',
  );
  const { isSubmitting, isValid, values } = useFormikContext<FormValues>();

  const hashRef = useRef<string>();

  useEffect(() => {
    const hashUser = async () => {
      hashRef.current = (await sha256Digest(user?.uid || '')) || 'unknown';
    };

    hashUser();
  }, [user]);

  if (!user || !wordingData || !surveyData || !classReportData?.results) {
    return <Loading />;
  }

  // The wording "url" is text data; extract it.
  const [mime, text] = wordingData.url.split(',');
  if (mime !== 'data:text/plain' || !text) {
    throw new Error('Invalid data URL or wrong mime type');
  }
  const wordingCondition = text as WordingCondition;

  // The survey url goes to Qualtrics.
  const surveyUrl = uri(surveyData.url)
    .setSearch({
      hid: hashRef.current,
      lcid: getLearningConditionId(classReportData.results),
    })
    .toString();

  return (
    <>
      <Modal.Title className="center">
        {conditionContent[wordingCondition].title}
      </Modal.Title>
      {conditionContent[wordingCondition].body && (
        <Row>
          <Col>{conditionContent[wordingCondition].body}</Col>
        </Row>
      )}

      <Row>
        <Col>
          <Input
            id="goToSurvey"
            name="goToSurvey"
            label={conditionContent[wordingCondition].label}
            labelPlacement="top"
            options={[
              { label: 'Yes', value: true },
              { label: 'No thanks', value: false },
            ]}
            component={Select}
            keyBy="value"
            keyName="label"
            disabled={isSubmitting}
            fullWidth
            displayErrors={false}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            type="submit"
            fullWidth
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
            data-testid="submit-btn"
            onClick={() => {
              if (values.goToSurvey) {
                window.open(surveyUrl);
              }
            }}
          >
            Submit Response
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <em>
            This question and practice resources are optional. Your confidential
            responses will help us share relevant resources and support research
            to serve future Elevate users.
          </em>
        </Col>
      </Row>
    </>
  );
};
