import { useClassReportData } from 'pages/Reports/ClassReport23/useClassReportData';
import { useCurrentUserQuery } from 'models';

const week = 7 * 24 * 60 * 60 * 1000;
const freshDuration = 3 * week;

// eslint-disable-next-line complexity
export const useShouldDisplay = () => {
  const classReportData = useClassReportData();
  const { data: user } = useCurrentUserQuery();

  if (classReportData === undefined || !user) {
    // Data has not loaded. Don't display prompt.
    return false;
  }

  const { cls, reportMetrics, results, lastUpdatedResults } = classReportData;

  if (!results) {
    // Resource Delivery Study prompt not displaying: no survey results.
    return false;
  }

  const userIsTeacher = user.managed_teams.includes(cls.uid || '');
  // Sure would be nice if we had metric sets in @perts/config.
  const hasElementaryMetrics = reportMetrics.some((m) =>
    /-elem$/.test(m.label),
  );
  const responseCount = results.sample.all_responses.all_participants;
  const overallPpnRate = responseCount / cls.num_students;
  const isDataFresh = lastUpdatedResults
    ? Number(new Date()) - Number(new Date(lastUpdatedResults)) < freshDuration
    : false;

  if (!userIsTeacher) {
    // Resource Delivery Study prompt not displaying: user is not a teacher.
    return false;
  }

  if (hasElementaryMetrics) {
    // Resource Delivery Study prompt not displaying: class has elementary
    // learning conditions
    return false;
  }

  if (cls.roster_locked && overallPpnRate < 0.6) {
    // Resource Delivery Study prompt not displaying: closed roster has
    // insufficient participation.
    return false;
  }

  if (!cls.roster_locked && responseCount < 5) {
    // Resource Delivery Study prompt not displaying: open roster has
    // insufficient responses.
    return false;
  }

  if (!isDataFresh) {
    // Resource Delivery Study prompt not displaying: report has not been
    // updated recently.
    return false;
  }

  return true;
};
