let PERTS = {};

try {
  // Browser enivronment.
  PERTS = window.PERTS || {};
} catch (e) {
  if (!(e instanceof ReferenceError)) {
    // Something weird happened.
    throw e;
  }
  // Else NodeJS environment (e.g. npm run faker) b/c there's no `window`.
}

export const NEPTUNE_PROTOCOL = PERTS.NEPTUNE_PROTOCOL || 'http';
export const NEPTUNE_DOMAIN = PERTS.NEPTUNE_DOMAIN || 'localhost:8080';
export const NEPTUNE_URL_PREFIX = `${NEPTUNE_PROTOCOL}://${NEPTUNE_DOMAIN}`;
export const NEPTUNE_PLATFORM_NAME = 'neptune';

export const TRITON_PROTOCOL = PERTS.TRITON_PROTOCOL || 'http';
export const TRITON_DOMAIN = PERTS.TRITON_DOMAIN || 'localhost:10081';
export const TRITON_URL_PREFIX = `${TRITON_PROTOCOL}://${TRITON_DOMAIN}`;
export const TRITON_PLATFORM_NAME = 'triton';

export const CANVAS_PROTOCOL = PERTS.CANVAS_PROTOCOL || 'http';
export const CANVAS_DOMAIN = PERTS.CANVAS_DOMAIN || 'canvas.docker';

export const FUNCTIONS_PROTOCOL = PERTS.FUNCTIONS_PROTOCOL || 'http';
export const FUNCTIONS_DOMAIN = PERTS.FUNCTIONS_DOMAIN || 'localhost';

export const OPTIMIZER_DOMAIN = PERTS.OPTIMIZER_DOMAIN || 'localhost:11080';
export const OPTIMIZER_URL_PREFIX = `${
  PERTS.isLocalhost ? 'http' : 'https'
}://${OPTIMIZER_DOMAIN}`;

export const { HCAPTCHA_SITE_KEY } = PERTS;

export const LOCAL_STORAGE_AUTH_USER = 'triton:auth:user';
export const LOCAL_STORAGE_AUTH_TOKEN = 'triton:auth:token';
export const LOCAL_STORAGE_GOOGLE_TOKEN = 'triton:auth:googleToken';
export const localStorageWelcomeDismissed = (userId) =>
  `triton:${userId}:UserWelcomeTutorial:dismissed`;

export const CONTACT_EMAIL_ADDRESS = 'copilot@perts.net';
export const CONTACT_NAME = 'Copilot';
// Used for routing purposes, to return back to new team creation.
export const NEW_TEAM_ID = 'Team_new';

export const GOOGLE_AUTH_API_KEY =
  PERTS.GOOGLE_AUTH_API_KEY || 'AIzaSyA4sB4P9lXUUGdrL1WU7I7sQIzPIOq-8D0';
export const GOOGLE_AUTH_DOMAIN =
  PERTS.GOOGLE_AUTH_DOMAIN || 'auth-dev.perts.net';
