import { sortBy } from 'lodash';

import defaultAntdLocale from './configAntd';
import { CANVAS_DOMAIN, CANVAS_PROTOCOL } from 'services/triton/config';

export {
  programAscendPattern,
  programCatalyzePattern,
  programElevatePattern,
} from '@perts/config';

// Moment.js date display formats
// See https://momentjs.com/docs/#/displaying for token definitions.

export const serverDateFormat = 'YYYY-MM-DD';
export const dateFormat = 'l'; // "6/9/2020"
export const dateFormatShort = 'M/D'; // "6/9"
export const dateTimeFormat = 'l LT'; // "6/9/2020 9:32 AM"
export const dateFormatTitle = 'MMM D'; // Jun 9

// Client-wide constants.

export const DEFAULT_PAGE_SIZE = 10;

export const canvasLoginLink = `${CANVAS_PROTOCOL}://${CANVAS_DOMAIN}/login/google`;
export const canvasLogoutLink = `${CANVAS_PROTOCOL}://${CANVAS_DOMAIN}/logout`;

export const channelExpandAll = '/expandAll'; // only used in OldReports
export const channelFullwindow = '/fullscreen';

// Reports: Apply same disaggregation to all
export const channelReportsSetAllDisaggregation =
  '/reports/setAllDisaggregation';
export const channelReportsSetAllShowBy = '/reports/setAllShowBy';

export const channelTablePrinting = '/tablePrinting';

export const channelOpenResponses = '/openResponses';

export const ROSTER_ID_MIN_LENGTH = 5;
export const ROSTER_ID_MAX_LENGTH = 100;
export const ROSTER_ID_PATTERN = new RegExp(`^\\S{${ROSTER_ID_MIN_LENGTH},}$`);

// The maximum number of owned_teams a user is allowed.
export const USER_OWNED_TEAMS_MAX = Infinity;

export const signupLink = 'https://perts.net/resources';

// Reports

// Percentage point difference. So a change from 50% to 56% in some student
// experience score would meet threshold. Use for positive and negative
// changes.
export const EXPERIENCE_CHANGE_THRESHOLD = 6;
// Percentage point difference. So the difference between a 50% experience score
// and the whole group's 60% score would meet threshold. Only used to
// highlight subgroups which score lower than the whole group.
export const EXPERIENCE_GAP_THRESHOLD = 10;
// Percent out of 100. If at least this proportion of students rate the
// fidelity item positively, it meets threshold.
export const FIDELITY_THRESHOLD = 80;

const PLACEHOLDER_ARTICLE_ID = '67000314178';

export const helpArticles = {
  // From "Resources" dropdown at top of reports.
  communityOfPracticeResources: {
    ascend: '67000676395',
    elevate: '67000676394',
  },
  howToUseReport: '67000676734',
  recommendedPratices: {
    ascend: 'https://perts.net/ascend/practices',
    catalyze: 'https://perts.net/catalyze/practices',
    elevate: 'https://perts.net/elevate/practices',
  },
  sharingYourReport: '67000669124',

  // Survey preview
  howToSeeFullSurvey: '67000441417',

  // Participation
  participationHowToUseCommunity: '67000677400',
  participationHowToUseNetwork: '67000679390',

  // In Reports.
  howToUseExportedData: '67000695877',
  learningConditionTrendsTable: '67000721404',
  participationByChildTable: '67000671400',
  participationBySubpopulationTable: '67000671399',
  ratedPositively: '67000671419',
  resultsBySubpopulationGraph: '67000671412',
  studentExperienceBySurveyGraph: '67000671410',
  studentExperienceOverviewTable: '67000671391',
  studentExperienceByCommunity: '67000671414',
  surveyFidelityTable: '67000671408',
  targetGroupSummary: '67000700045',
  cantFindMyReports: '67000685183',

  // Elsewhere in the app.
  pasteEmailAddresses: '67000675045',
  surveySchedules: '67000441398',
  whatArePins: '67000702255',
  whatAreSignOnTypes: '67000702155',
  whatIsTargetGroup: '67000443219',
  whatIsRosterLocking: '67000676915',
  whatIsRosterIDRule: '67000683649',
  pertsAppliedRuleToRoster: '67000683649',
  aboutSurveySchedules: '67000441398',
  studentEmailAdressAsRosterIds: '67000441401',
  leadsAreAddedToCommunities: '67000674999',
  enableQuestionsAndOpenResponses: '67000676821',
  whatIsAMember: '67000674999',
  whatIsACommunity: '67000443235',
  genderIdentity: '67000671964',

  // GuideBox
  guideboxHome: '67000443235',
  guideboxMembers: '67000678226',
  guideboxClasses: '67000676746',
  guideboxSchedules: '67000441398',
  guideboxSurveys: '67000441435',
  // guideboxParticipation: 'THERE-IS-NO-PARTICIPATION-ARTICLE',
  guideboxReports: '67000675046',

  // Default settings
  whatAreDefaults: '67000691756',
  whatAreParentCommunities: PLACEHOLDER_ARTICLE_ID,
};

// Settings for Antd.

export const antdLocale = {
  ...defaultAntdLocale,
  lang: {
    ...defaultAntdLocale.lang,
    rangePlaceholder: ['Start', 'End'], // allows RangePicker to be narrower
  },
};

// Subject area values.

export const subjectAreas = [
  ...sortBy(
    [
      { name: 'English/Language Arts', key: 'english-language-arts' },
      { name: 'History/Social Studies', key: 'history-social-studies' },
      { name: 'Health/Physical Education', key: 'health-physical-education' },
      { name: 'Mathematics', key: 'mathematics' },
      { name: 'Science', key: 'science' },
      { name: 'Visual/Performing Arts', key: 'visual-performing-arts' },
      { name: 'World Language', key: 'world-language' },
    ],
    'name',
  ),
  {
    name: 'This class falls into none of the categories listed.',
    key: 'other',
  },
  { name: 'Prefer not to say', key: 'opt-out' },
];
